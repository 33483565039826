import React from 'react';
import VisitenkarteImg from '../../Website/ImagesFiles/handy_visitenkarte_2.png';
import SpiegelBoxImg from '../../Website/ImagesFiles/spiegel_box_2.png';
import UhrenImg from '../../Website/ImagesFiles/uhren_2.png';
import UntersetzerImg from '../../Website/ImagesFiles/untersetzer_2.png';
import CapImg from '../../Website/ImagesFiles/cap_black_front.png';
import TanktopImg from '../../Website/ImagesFiles/tanktop_black_front.png';
import HoodieImg from '../../Website/ImagesFiles/hoodie_white_back.png';
import TShirtImg from '../../Website/ImagesFiles/t-shirt_black_back.png';
import FrontendHeader from "../../Website/Frontend/FrontendHeader";
import FrontendFooter from "../../Website/Frontend/FrontendFooter";

const FrontendHome = () => {

    return (
        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white'>
                {<FrontendHeader/>}
                <section id="showcase-laser"
                         className="bg-cover bg-center h-36 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold">Veredelung, Textilien & Druck</h1>
                    </div>
                </section>
                <section>
                    <div className="container mx-auto p-6 ">

                        <div className="mb-10 border-2 rounded-xl p-5">
                            <h2 className="text-xl font-semibold mb-2">Gravur und Schnitt mit Laser</h2>
                            <p className="mb-4">
                                Unsere Lasergravur- und Laserschnittdienste ermöglichen präzise Gravuren und Schnitte
                                auf einer Vielzahl von Materialien wie Holz, Acryl, Glas und Metall. Ideal für
                                personalisierte Geschenke, Dekorationsartikel und mehr.
                            </p>
                            <section>
                                <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-4 sm:gap-4 pb-5">
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={VisitenkarteImg} alt="Handyhülle und Visitenkarte"
                                             className="h-72"/>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={SpiegelBoxImg} alt="Spiegelbox"
                                             className="h72"/>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={UhrenImg} alt="Uhren"
                                             className="h-72"/>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={UntersetzerImg} alt="Untersetzer"
                                             className="h-72"/>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="mb-8 border-2 rounded-xl p-5">
                            <h2 className="text-xl font-semibold mb-2">Textilien</h2>
                            <p className="mb-4">
                                Wir bieten Dir eine langlebige und vielseitige Methode zur Veredelung
                                Deiner Textilien. Ob T-Shirts, Hoodies oder Taschen – wir realisieren Deine individuellen
                                Wünsche:
                            </p>
                            <section>
                                <div className="grid grid-cols-1 gap-1 sm:grid sm:grid-cols-4 sm:gap-4 pb-5">
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={CapImg} alt="Cap"
                                             className="h-72"/>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={HoodieImg} alt="Top und Shirt"
                                             className="h-72"/>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={TanktopImg} alt="Shirt"
                                             className="h-72"/>
                                    </div>
                                    <div className="w-full p-2 rounded shadow-md sm:shadow-xl justify-center flex">
                                        <img src={TShirtImg} alt="Hoodie"
                                             className="h-72"/>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
                {<FrontendFooter/>}
            </div>
        </main>


    );
}

export default FrontendHome;