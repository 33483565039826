import React from "react";

class InputText extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputid: props.id,
            label: props.label,
            placeholder: (undefined !== props.placeholder) ? props.placeholder : ''
        };
    }

    handleChangeText = (event) => {
        const text = event.target.value;
        this.props.onChange(this.props.id, text);
    }

    getClasses = () => {
        let classes = 'block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4';

        if (true === this.props.show) {
            if (false !== this.props.error && '' === this.props.value) {
                classes = 'block text-red-500 font-bold md:text-right mb-1 md:mb-0 pr-4';
            }
        }
        return classes;
    }


    render() {
        return (
            ('' !== this.state.label) ?
                <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/4">
                        <label className={this.getClasses()}
                               htmlFor="inline-full-name">
                            {this.state.label} {(true === this.props.show) ? <span>&lowast;</span> : ''}
                            {(true === this.props.show) ? (false !== this.props.error && '' === this.props.value) ?
                                <span
                                    className="text-red-500 text-sm w-full flex justify-end">Bitte {this.state.label} ausf&uuml;llen.</span> : '' : ''}
                        </label>
                    </div>
                    <div className="md:w-3/4">
                        <input
                            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id={this.state.inputid}
                            type="text"
                            value={this.props.value}
                            placeholder={this.state.placeholder}
                            onChange={this.handleChangeText}/>
                    </div>
                </div>
                : <div className="md:flex md:items-center">
                    <div className="md:w-full">
                        <input
                            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                            id={this.state.inputid}
                            type="text"
                            value={this.props.value}
                            placeholder={this.state.placeholder}
                            onChange={this.handleChangeText}/>
                    </div>
                </div>
        )
    }
}
export default InputText;