import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {RequireToken} from "./Components/Website/Auth";
import Login from "./Components/Website/Login";
import FrontendHome from "./Components/Content/Frontend/FrontendHome";
import BackendHome from "./Components/Content/Backend/BackendHome";
import FrontendFlex from "./Components/Content/Frontend/FrontendFlex";
import FrontendLaser from "./Components/Content/Frontend/FrontendLaser";
import FrontendContact from "./Components/Content/Frontend/FrontendContact";
import FrontendDatenschutzhinweis from "./Components/Content/Frontend/FrontendDatenschutzhinweis";
import FrontendImpressum from "./Components/Content/Frontend/FrontendImpressum";
import BackendArticleList from "./Components/Content/Backend/Article/BackendArticleList";
import BackendArticleImport from "./Components/Content/Backend/Article/BackendArticleImport";

const App = () => {
    return (
        <div className="bg-gray-200">
            {/*<a href="/">Home |</a>*/}
            {/*<a href="/backend"> Backend</a>*/}
            <Router>
                <Routes>
                    <Route path="/" element={<FrontendHome/>}/>
                    <Route path="/flexdruck" element={<FrontendFlex/>}/>
                    <Route path="/lasergravur" element={<FrontendLaser/>}/>
                    <Route path="/kontakt" element={<FrontendContact/>}/>
                    <Route path="/datenschutzerklaerung" element={<FrontendDatenschutzhinweis/>}/>
                    <Route path="/impressum" element={<FrontendImpressum/>}/>
                    <Route path="/backend" element={
                        <RequireToken>
                            <BackendHome/>
                        </RequireToken>
                    }/>
                    <Route path="/backend/article/list" element={
                        <RequireToken>
                            <BackendArticleList/>
                        </RequireToken>
                    }/>
                    <Route path="/backend/article/import" element={
                        <RequireToken>
                            <BackendArticleImport/>
                        </RequireToken>
                    }/>
                    {/*<Route path="/backend/article/new" element={*/}
                    {/*    <RequireToken>*/}
                    {/*        <BackendArticleNew/>*/}
                    {/*    </RequireToken>*/}
                    {/*}/>*/}
                    {/*<Route path="/backend/user/create" element={*/}
                    {/*    <RequireToken>*/}
                    {/*        <BackendUserCreate/>*/}
                    {/*    </RequireToken>*/}
                    {/*}/>*/}
                    {/*<Route path="/backend/user/create-address/:createduserid/:createduseremail" element={*/}
                    {/*    <RequireToken>*/}
                    {/*        <BackendUserAddress/>*/}
                    {/*    </RequireToken>*/}
                    {/*}/>*/}
                    <Route path="/login" element={<Login/>}/>
                </Routes>
            </Router>
        </div>
    );
};

export default App;
