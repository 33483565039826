import React from 'react';
import BackendHeader from "../../../Website/Backend/BackendHeader";
import BackendFooter from "../../../Website/Backend/BackendFooter";
import ArticleList from "./ArticleList";


const BackendHome = () => {

    // const user = UserUtils.getUser();

    return (


        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white border-2'>

                {<BackendHeader/>}

                <section id="showcase-laser"
                         className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold pb-4">Artikel Liste</h1>
                    </div>
                </section>

                <section className="p-8">
                    <div className="p-5 bg-white">
                        <section>
                            <div className="pb-5">
                                <div className="w-full p-2">
                                    {<ArticleList/>}
                                </div>
                            </div>
                        </section>
                    </div>
                </section>

                {<BackendFooter/>}

            </div>
        </main>

    )
        ;

}

export default BackendHome;