class FormUtils {

    static sanitizeInput = (input) => {
        const div = document.createElement('div');
        div.textContent = input;
        return div.innerHTML;
    };

    // Function to strip HTML tags, scripts, and SQL queries
    static stripInput = (input) => {
        // Regex to remove HTML tags
        input = input.replace(/<\/?[^>]+(>|$)/g, "");

        // Regex to remove SQL-like statements (basic example)
        input = input.replace(/(SELECT|INSERT|DELETE|UPDATE|CREATE|DROP|ALTER)\s/gi, "");

        // Regex to remove JavaScript
        input = input.replace(/<script.*?>.*?<\/script>/gi, "");

        return input;
    };

    static checkBoxChecked = (input) => {
        return input === true;
    }

    static inputFieldEmpty = (input) => {
        return input === true;
    }

    static isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);


}

export default FormUtils;