import React, {useState} from 'react';
import {useNavigate} from "react-router";
import {removeToken} from "../Auth";
import UserUtils from "../User/UserUtils";
import logout from "../ImagesFiles/logout.png";
import BannerStart from "../ImagesFiles/header_backend_3.png";

const TopnaviBackendAdmin = () => {

    const navigate = useNavigate();
    const banner = BannerStart;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);


    const signOut = () => {
        removeToken();
        UserUtils.removeUser();
        navigate("/");
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };

    return (
        <div className="bg-gray-800 text-white text-center">
            <nav className="container mx-auto p-4 flex justify-between items-center">
                <div className="hidden md:flex space-x-4">
                    <a href="/" className="hover:bg-gray-700 px-3 py-2 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20"
                             viewBox="0 0 50 50" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M 25 1.0507812 C 24.7825 1.0507812 24.565859 1.1197656 24.380859 1.2597656 L 1.3808594 19.210938 C 0.95085938 19.550938 0.8709375 20.179141 1.2109375 20.619141 C 1.5509375 21.049141 2.1791406 21.129062 2.6191406 20.789062 L 4 19.710938 L 4 46 C 4 46.55 4.45 47 5 47 L 19 47 L 19 29 L 31 29 L 31 47 L 45 47 C 45.55 47 46 46.55 46 46 L 46 19.710938 L 47.380859 20.789062 C 47.570859 20.929063 47.78 21 48 21 C 48.3 21 48.589063 20.869141 48.789062 20.619141 C 49.129063 20.179141 49.049141 19.550938 48.619141 19.210938 L 25.619141 1.2597656 C 25.434141 1.1197656 25.2175 1.0507812 25 1.0507812 z M 35 5 L 35 6.0507812 L 41 10.730469 L 41 5 L 35 5 z"></path>
                        </svg>
                    </a>
                    <div className="relative">
                        <button onClick={toggleSubMenu} className="bg-gray-700 px-3 py-2 rounded">Artikel</button>
                        {isSubMenuOpen && (
                            <div className="relative text-white rounded mt-2">
                                <a href="/backend/article/list"
                                   className="block px-3 py-2 rounded hover:bg-gray-700">Liste</a>
                                <a href="/backend/article/import"
                                   className="block px-3 py-2 rounded hover:bg-gray-700">Imprtieren</a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="focus:outline-none">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                  d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>
                <div className="text-sm text-gray-500 flex">
                    <a href="/backend">
                        <picture>
                            <img className="w-auto h-10" src={banner} alt="" loading=""/>
                        </picture>
                    </a>
                    <button onClick={signOut} className="focus:outline-none">
                        <img src={logout} alt="logout" className="w-6 text-white"/> {(!UserUtils.getUser()) ? "" :
                        UserUtils.getUser().username
                    }
                    </button>
                </div>
            </nav>
            {isMenuOpen && (
                <div id="nav-menu" className="md:hidden bg-gray-700 text-white">
                    <a href="/" className="block px-4 py-2 hover:bg-gray-600">Home</a>
                    <div className="relative">
                        <button onClick={toggleSubMenu} className="block px-4 py-2 hover:bg-gray-600">Artikel</button>
                        {isSubMenuOpen && (
                            <div className="pl-4">
                                <a href="/backend/article/list" className="block px-4 py-2 hover:bg-gray-600">Liste</a>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default TopnaviBackendAdmin;