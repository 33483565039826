import React, {useEffect, useState} from 'react';
import axios from "axios";
import {getUrl} from "../../../Website/Config";
import ListItemArticle from "../../../Website/List/ListItemArticle";

const ArticleList = () => {
    const [article, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                const response = await axios.get(getUrl() + 'contact/new', axiosConfig);
                const requestData = response.data.data;
                const articleData = requestData.map((data) => (
                    <ListItemArticle
                        id={data.id}
                        key={data.id}
                        remarks={data.remarks}
                        name={data.name}
                        email={data.email}
                        subject={data.subject}
                        read={data.read}
                    />
                ));
                setArticles(articleData);
            } catch (error) {
                console.error('Error fetching messages', error);
            }
        };

        fetchArticles();
    }, []);

    return (
        <span>
            {article}
        </span>
    );
}

export default ArticleList;
