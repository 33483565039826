import React, {useState} from 'react';
import FrontendHeader from "../../Website/Frontend/FrontendHeader";
import FrontendFooter from "../../Website/Frontend/FrontendFooter";
import axios from "axios";
import InputText from "../../Website/Form/InputText";
import InputEmail from "../../Website/Form/InputEmail";
import InputTextArea from "../../Website/Form/InputTextArea";
import Button from "../../Website/Form/Button";
import InputCheckbox from "../../Website/Form/InputCheckbox";
import FormUtils from "../../Website/Form/FormUtils";
import {getUrl} from "../../Website/Config";

const FrontendContact = () => {

    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const [state, setState] = useState({
        name: '',
        email: '',
        subject: '',
        remarks: '',
        confirm: false,
    });

    const handleFieldChange = (inputFieldId, inputFieldValue) => {
        setState({...state, [inputFieldId]: inputFieldValue});
    }

    const validateForm = () => {

        let error = false;
        if (!FormUtils.checkBoxChecked(state.confirm) ||
            FormUtils.inputFieldEmpty(!state.email) ||
            FormUtils.inputFieldEmpty(!state.subject) ||
            !FormUtils.isEmail(state.email)) {

            error = true;
        }

        if (true === error) {
            setError(true);
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        FormUtils.stripInput(FormUtils.sanitizeInput(state.name));
        FormUtils.stripInput(FormUtils.sanitizeInput(state.remarks));

        try {

            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const contactData = {
                name: state.name,
                email: state.email,
                subject: state.subject,
                remarks: state.remarks,
            };

            axios.post(getUrl() + 'contact', contactData, axiosConfig)
                .then(function (response) {
                    if (response.status === 201) {
                        setState({
                            name: '',
                            email: '',
                            subject: '',
                            remarks: '',
                        });
                        setError(false);
                        setMessage('Ihre Anfrage wurde erfolgreich gesendet.')
                    } else {
                        setMessage('Es gab einen Fehler beim Senden Ihrer Anfrage (Status ' + response.status + ').');
                        setError(true);
                    }
                })
                .catch(error => {
                    let infoMessage = error.message;
                    setMessage('Es gab einen Fehler beim Senden Ihrer Anfrage: ' + infoMessage);
                    setError(true);
                    console.error('Fehler beim Senden der Anfrage', infoMessage);
                });

        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
            setMessage('Es gab einen Fehler beim Senden Ihrer Anfrage.');
            setError(true);
        }
    };

    return (
        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white border-2'>

                {<FrontendHeader/>}

                <section id="showcase-laser"
                         className="bg-cover bg-center h-64 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold pb-4">Kontakt</h1>
                        <h2 className="text-sm font-semibold mb-4 text-center">
                            02598 / 83 19 771&nbsp;&nbsp;|&nbsp;&nbsp;info[at]lagocki.de</h2>
                    </div>
                </section>

                <section className="p-8">
                    <p>
                        Du interessierst Dich für unsere Produkte und Dienstleistungen im Bereich Flexdruck und
                        Lasergravur?
                        Kontaktiere uns gerne über das Kontaktformular oder ruf uns an.
                    </p>
                </section>

                <section className="p-8">
                    <div className="mt-10 p-5 bg-white shadow-md rounded-lg">

                        <form id="contact-form">
                            <div className="mb-4 justify-end w-full flex text-sm">
                                notwendige Angaben sind mit einem Sternchen (&lowast;) gekennzeichnet.
                            </div>
                            <div className="mb-4">
                                <InputText
                                    id="name"
                                    label="Dein Name"
                                    error={error}
                                    show={false}
                                    onChange={handleFieldChange}
                                    // onChange={(e) => setName(e.target.value)}
                                    value={state.name}
                                />
                            </div>
                            <div className="mb-4 ">
                                <InputEmail
                                    id="email"
                                    label="Deine Email Adresse"
                                    error={error}
                                    show={true}
                                    valid={FormUtils.isEmail(state.email)}
                                    onChange={handleFieldChange}
                                    value={state.email}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <InputText
                                    id="subject"
                                    label="Betreff"
                                    error={error}
                                    show={true}
                                    onChange={handleFieldChange}
                                    value={state.subject}
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <InputTextArea
                                    id="remarks"
                                    label="Deine Nachricht"
                                    error={error}
                                    onChange={handleFieldChange}
                                    value={state.remarks}
                                />
                            </div>
                            <div className="mb-4">
                                <InputCheckbox
                                    id="confirm"
                                    show={true}
                                    error={error}
                                    label="Die Datenschutzerklärung habe ich zur Kenntnis genommen &lowast;"
                                    checked={state.confirm}
                                    change="Datenschutzerklärung"
                                    link="datenschutzerklaerung"
                                    onChange={handleFieldChange}/>
                            </div>
                            <div className="mb-4 justify-end w-full flex">
                                <Button
                                    label="Senden"
                                    onClick={handleSubmit}
                                />
                            </div>
                        </form>
                        {(error && message !== '') ?
                            <p className="mt-4 text-center text-red-500 text-xl p-5">{message}</p> :
                            <p className="mt-4 text-center text-green-500 text-xl p-5">{message}</p>}
                    </div>
                </section>

                {<FrontendFooter/>}

            </div>
        </main>


    );
}

export default FrontendContact;