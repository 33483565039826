import React, {useState} from 'react';
import BackendHeader from "../../../Website/Backend/BackendHeader";
import BackendFooter from "../../../Website/Backend/BackendFooter";

const BackendArticleImport = () => {


    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);
    // eslint-disable-next-line no-undef,no-mixed-operators
    const [data, setData] = useState<any>([]);



    return (
        <main className="md:pt-1 pb-20">
            <div className='flex justify-center max-w-7xl flex-col mx-auto bg-white border-2'>

                {<BackendHeader/>}

                <section id="showcase-laser"
                         className="bg-cover bg-center h-24 flex items-center justify-center text-white bg-[#3f888f]">
                    <div className="text-center">
                        <h1 className="text-4xl font-bold pb-4">Artikel importieren</h1>
                    </div>
                </section>

                <section className="p-8">
                    <div className="p-5 bg-white">

                        {(error && message !== '') ?
                            <p className="mt-4 text-center text-red-500 text-xl p-5">{message}</p> :
                            <p className="mt-4 text-center text-green-500 text-xl p-5">{message}</p>}
                    </div>
                </section>

                {<BackendFooter/>}

            </div>
        </main>

    )
        ;

}

export default BackendArticleImport;